import { useEffect, useState, useRef, Fragment, useMemo } from "react";
import {
  Button,
  Modal,
  Table,
  InputGroup,
  Popover,
  Overlay,
  Form,
  Dropdown,
} from "react-bootstrap";
import ArrowCollapseDownIcon from "mdi-react/ArrowCollapseDownIcon";
import "./../../assets/scss/requisition/index.scss";
import {
  BookIcon,
  FileLargeIcon,
  LinkVariantIcon,
  Logo,
  MessageIcon,
  PrintIcon,
  RecieptIcon,
  SignatureIcon,
  UserIcon,
} from "../Icons";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { services, HRFrontEndUrl } from "../../config";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { format } from "date-fns";
import CurrencyInput from "react-currency-input-field";
import { find, isEmpty, lowerCase, max, size, uniq } from "lodash";
import {
  copyText,
  formDataForFormBuider,
  formatDate,
  formatNumberSystem,
  getUserThatRequestedToMe,
  isInventoryOrFundRequest,
  maxTopPopperConfig,
  nanoid,
  searchParamsToObject,
  styleHelper,
  waitFor,
} from "../../utils/helpers";
import useDebounce, {
  useCanGoBack,
  useCurrencies,
  useHistoryStack,
  useIsAdmin,
  useIsCashier,
  useIsProcurement,
} from "../../utils/hooks";
import { toast } from "react-toastify";
import { requisitionActions } from "../../utils/reactQueryActions";
import currency from "currency.js";

import ItemsTable from "../utils/ItemsTable";
import { useAuth } from "../../hooks/useAuth";
import { last } from "lodash";
import { LinkWithQuery as Link } from "../utils/LinkWithQuery";
import {
  ApproveAndCloseDialog,
  RejectDialog,
  LeaveCommentDialog,
  ApproveOnlyDialog,
} from "./Dialog";
// import printJS from "print-js";
import { fetchActionsUtil } from "../../utils/helpers";
import { LoanAccountModal } from "../modals/LoanAccountModal";
import ApprovalBadge from "../utils/ApprovalBadge";
import CurrencyCustomInput from "../utils/CurrencyCustomInput";
import { useFormik } from "formik";
import FundRequestForm from "./FundRequestForm";
import CachedIcon from "mdi-react/CachedIcon";

import eventBus from "../../utils/EventBus";
import ModalLoader from "../utils/ModalLoader";

import MagnifyIcon from "mdi-react/MagnifyIcon";
import CropPortraitIcon from "mdi-react/CropPortraitIcon";
import CropLandscapeIcon from "mdi-react/CropLandscapeIcon";
import ShareVariantIcon from "mdi-react/ShareVariantIcon";
import { useStoreState } from "easy-peasy";
import * as yup from "yup";
import Datetime from "react-datetime";
import AllowanceRequest from "./AllowanceRequest";
import PORequisitionModal from "./PORequisition";
import GeneralItemIssuerModal from "../modals/GeneralItemIssuerModal";
import { FilesAttachments } from "../../utils/Attachments";
import ConfirmDialog from "../ConfirmDialogue";
import RequisitionApproveFooter from "./RequisitionApproveFooter";
import PurchaseOrderDistributionModal from "../Vendor/PurchaseOrderDistributionModal";
import DotsHorizontalIcon from "mdi-react/DotsHorizontalIcon";
import InItemsRequisitionModal from "./InItemsRequisition";
import RFQCompanyModal from "../RFQCompanyModal";
import UndoVariantIcon from "mdi-react/UndoVariantIcon";

export default function PreviewModal({ location }) {
  const currentLoggedInCompany = useStoreState(
    (state) => state.currentLoggedInCompany
  );

  const generalSettings = useStoreState((state) => state.generalSettings);
  const initialRequestData = (requisition = {}) => {
    return lowerCase(requisition?.type) === "fund request" ||
      lowerCase(requisition?.type) === "retirement for fund request" ||
      lowerCase(requisition?.type) === "budget"
      ? {
        description: "",
        unitCost: "",
        quantity: "",
      }
      : lowerCase(requisition?.type) === "material request"
        ? {
          barcode: "",
          itemName: "",
          description: "",
          quantityRequested: "",
          availability: "",
          quantityApproved: "",
        }
        : {
          barcode: "",
          itemName: "",
          unitCost: "",
          quantity: "",
        };
  };

  let { id } = useParams();
  const iframeRef = useRef();
  const [searchParams, setSearchParams] = useSearchParams();
  const goBack = useCanGoBack();
  const isAdmin = useIsAdmin();
  const isCashier = useIsCashier();
  const isProcurement = useIsProcurement();
  const { deploymentCurrencies: currenciesOptions } = useCurrencies();

  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [tableData, setTableData] = useState([]);
  const [total, setTotal] = useState(0);
  const [fundRequest, setFundRequest] = useState(initialRequestData());
  const { user: authUser, backendUrl } = useAuth();
  const [file, setFile] = useState();
  const [loanAmount, setLoanAmount] = useState(null);
  const [salaryAmount, setSalaryAmount] = useState(null);
  const [salaryMonth, setSalaryMonth] = useState(null);
  const [openAccountModal, setOpenAccountModal] = useState(false);
  const [isLoadingIframe, setIsLoadingIframe] = useState(true);
  const [reciever, setReceiver] = useState();
  const [ShowNewPoRequisitionModal, setShowNewPoRequisitionModal] = useState(
    false
  );
  const [ShowNewRFQRequisitionModal, setShowNewRFQRequisitionModal] = useState(
    false
  );
  const [showGeneralItemIssuer, setShowGeneralItemIssuer] = useState(false);
  const [attachments, setAttachments] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [
    showPurchaseOrderDistributionModal,
    setShowPurchaseOrderDistributionModal,
  ] = useState(false);

  const [RFQData, setRFQData] = useState({});
  const [showCreateQuoteModal, setShowCreateQuoteModal] = useState(false);
  const [IsQuote, setIsQuote] = useState(false);

  //const [requestID, setRequestID] = useState(null);

  const formik = useFormik({
    initialValues: { expectedDeliveryDate: new Date(), projectDescription: "" },
    validationSchema: yup.object().shape({}),
    onSubmit: (values) => { },
  });

  const getNextUserToPerformAction = (requisition) => {
    const requisitiontracks = requisition.requisitiontracks;
    //---------------------------------------------------------------------
    let permissions = [];
    const page = JSON.parse(requisition.filledFormData)[0];
    const signatories = page.children
      .filter((el) => el?.type === "signatoryElement")
      .map((el) => el.props.fields);
    if (signatories) {
      permissions = signatories.flat().map((el) => el.permission);

      if (permissions) {
        //  console.log(permissions, requisitiontracks.length);
        //  user we want  to  send  to
        const lastTrack = requisitiontracks.length + 1;
        setReceiver(permissions[lastTrack]);
      }
    }
  };

  const getNextUserToPerformActionForSystemForm = (requisition) => {
    const requisitiontracks = requisition?.requisitiontracks;
    let permissions = JSON.parse(requisition?.preSelectedSignatory);

    if (permissions) {
      //  user we want  to  send  to
      const lastTrack = requisitiontracks.length + 1;
      setReceiver(permissions[lastTrack]);
      formik.setFieldValue("department", permissions[lastTrack]?.department);
      formik.setFieldValue("recipient", permissions[lastTrack]?.staff);
      formik.setFieldValue("jobGrade", permissions[lastTrack]?.jobGrade);
    }
  };

  const canApproveAndClose = (requisition = {}) => {
    const resolveLimit = (selection) => {
      if (selection?.limitType === "any") return Infinity;
      return selection && selection.limit ? Number(selection.limit) : 0;
    };

    // check if user is second level approval
    if (
      ["fund request"].includes(lowerCase(requisition?.type)) &&
      generalSettings?.secondLineFundRequestApproval &&
      generalSettings.secondLineFundRequestApproval.find(
        (el) => el.staff === authUser.Staff_ID
      )
    ) {
      const permissions = generalSettings?.secondLineFundRequestApproval
        ? generalSettings.secondLineFundRequestApproval
        : [];

      const forStaff = permissions.find((el) => el.staff === authUser.Staff_ID);
      const anyDepartment = permissions.find(
        (el) => el.department === "" && el.staff === ""
      );
      const anyJobGrade = permissions.find(
        (el) => el.department === authUser.Department && el.jobGrade === ""
      );
      const anyStaff = permissions.find(
        (el) =>
          el.department === authUser.Department &&
          el.jobGrade === authUser.jobGrade &&
          el.staff === ""
      );

      const limits = [
        resolveLimit(forStaff),
        resolveLimit(anyDepartment),
        resolveLimit(anyJobGrade),
        resolveLimit(anyStaff),
      ];

      const highestLimit = max(limits);
      return Number(requisition?.amount) <= Number(highestLimit);
    }
    // -----------------------------------------------------------------------------------------

    // Fund Request Check if Max has Approved --------------------------------------------------
    if (
      ["fund request", "material request", "allowance"].includes(
        lowerCase(requisition?.type)
      ) &&
      requisition.requiredSignatoriesStaff_ID
    ) {
      let permissions = JSON.parse(requisition.requiredSignatoriesStaff_ID);

      //  for Staff
      const forStaff = permissions.find((el) => el.staff === authUser.Staff_ID);

      //  Department
      const anyDepartment = permissions.find(
        (el) => el.department === "" && el.staff === ""
      );

      // Job Grade
      const anyJobGrade = permissions.find(
        (el) => el.department === authUser.Department && el.jobGrade === ""
      );

      //
      const anyStaff = permissions.find(
        (el) =>
          el.department === authUser.Department &&
          el.jobGrade === authUser.jobGrade &&
          el.staff === ""
      );

      if ("fund request" === lowerCase(requisition?.type)) {
        const limits = [
          resolveLimit(forStaff),
          resolveLimit(anyDepartment),
          resolveLimit(anyJobGrade),
          resolveLimit(anyStaff),
        ];

        const highestLimit = max(limits);
        /*  console.log(
          highestLimit,
          requisition?.amount,
          Number(requisition?.amount) <= Number(highestLimit)
        ); */
        //  console.log(forStaff, anyDepartment, anyJobGrade, anyStaff);
        return Number(requisition?.amount) <= Number(highestLimit);
      } else {
        // console.log(forStaff, anyDepartment, anyJobGrade, anyStaff);
        return forStaff || anyDepartment || anyJobGrade || anyStaff;
      }
    } else {
      return true;
    }
  };

  const getRequisitionDetails = async (id) => {
    // await waitFor(5000);
    let response = await fetch(`${backendUrl}/api/requisition/${id}`, {
      method: "GET",
      headers: {
        Accept: "Application/json",
        "Content-Type": "Application/json",
      },
      credentials: "include",
    });

    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }

    const { data } = await response.json();
    //  force rerender
    data.requisition.timestamp = String(Date.now());

    if (isInventoryOrFundRequest(data.requisition.type)) {
      setTableData(
        pullDataFromString({
          text: data.requisition.description,
          requisitionType: data.requisition.type,
          poVendor: data.po,
        })
      );
    }
    if (data.requisition?.filledFormData) {
      getNextUserToPerformAction(data.requisition);
      setIsLoadingIframe(true);
    } else if (data.requisition?.preSelectedSignatory) {
      getNextUserToPerformActionForSystemForm(data.requisition);
      setIsLoadingIframe(false);
    } else if (
      ["fund request", "material request", "allowance", "permission"].includes(
        lowerCase(data.requisition?.type)
      )
    ) {
      //  getNextUserToPerformAction(data.requisition);
      canApproveAndClose(data.requisition);
      setIsLoadingIframe(false);
    } else {
      setIsLoadingIframe(false);
    }
    // Set form values
    if (data.requisition) {
      const requisitionDataFromServer = data.requisition;
      formik.setValues({
        ...formik.values,
        expectedDeliveryDate: requisitionDataFromServer?.expectedDeliveryDate
          ? new Date(requisitionDataFromServer?.expectedDeliveryDate)
          : new Date(),
        projectDescription: requisitionDataFromServer.projectDescription,
      });
    }

    //
    setFundRequest({ ...initialRequestData(data.requisition) });

    if (data?.requisition?.initialFiles) {
      setAttachments(JSON.parse(data.requisition.initialFiles));
    }
    return data;
  };

  const {
    data: {
      requisition,
      company,
      orientation,
      pageSize,
      po,
      rfq,
      items = [],
      expensedAccounts = [],
      attachedRequest,
    } = {
      requisition: null,
      company: null,
      orientation: null,
      pageSize: null,
    },
    isFetching,
    refetch,
  } = useQuery(
    [requisitionActions.GET_REQUISITION_DETAILS, id],
    () => getRequisitionDetails(id),
    {
      onError: (err) => {
        console.log(err);
      },
      onSuccess: (data) => { },
    }
  );

  useEffect(() => {
    if (requisition) {
      const queryParams = searchParamsToObject(searchParams.entries());
      if (queryParams.action) {
        if (canPerformAction(requisition)) {
          if (queryParams.action === "approveAndClose") {
            approveAndClose();
          } else if (queryParams.action === "reject") {
            reject();
          }
        }
        delete queryParams.action;
        setSearchParams({ ...queryParams });
      }

      if (
        lowerCase(requisition.type) === "loan" ||
        lowerCase(requisition.type) === "salary"
      ) {
        setLoanAmount(requisition.amount);
      }

      if (lowerCase(requisition.type) === "salary") {
        setSalaryAmount(requisition.amount);
        setSalaryMonth(JSON.parse(requisition?.loanDetails)?.payrollMonth);
      }
      if (["rfq", "po"].includes(lowerCase(requisition?.type))) {
        setAttachments(JSON.parse(requisition.initialFiles));
      }

      //console.log({ requisition });
    }
  }, [requisition]);

  const getRFQItems = async () => {
    try {
      const { vendorRFQ, quote } = await fetchActionsUtil(
        `${backendUrl}/api/vendors/vendor-forms/rfq?requestid=${id}`
      );

      setRFQData(vendorRFQ);
      setIsQuote(quote);
    } catch {
      toast.error("Unable to update");
    }
  };

  useEffect(() => {
    if (lowerCase(requisition?.type) === "rfq") {
      getRFQItems();
    }
  }, [requisition]);

  const reject = async ({ isReservedUser } = { isReservedUser: false }) => {
    // console.log(isReservedUser);
    await RejectDialog({
      authUser,
      requisitionTitle: requisition.title,
      requisition: last(requisition?.requisitiontracks),
      previousRequisitionTrackId: last(requisition?.requisitiontracks).ID,
      isReservedUser,
    });
  };

  const approveAndClose = async (
    { isReservedUser } = { isReservedUser: false }
  ) => {
    if (
      lowerCase(requisition.type) === "fund request" &&
      isEmpty(requisition?.paymentType)
    ) {
      await ApproveAndCloseDialog({
        authUser,
        requisitionTitle: requisition.title,
        requisition: last(requisition?.requisitiontracks),
        previousRequisitionTrackId: last(requisition?.requisitiontracks).ID,
        isReservedUser,
      });

      //  navigate(`/requisition/request/approve-and-close/${id}`);
    } else {
      await ApproveAndCloseDialog({
        authUser,
        requisitionTitle: requisition.title,
        requisition: last(requisition?.requisitiontracks),
        previousRequisitionTrackId: last(requisition?.requisitiontracks).ID,
        tableData,
        isReservedUser,
      });
    }
  };

  const approveOnly = async (
    { isReservedUser } = { isReservedUser: false }
  ) => {
    if (
      lowerCase(requisition.type) === "fund request" &&
      isEmpty(requisition?.paymentType)
    ) {
      await ApproveOnlyDialog({
        authUser,
        requisitionTitle: requisition.title,
        requisition: last(requisition?.requisitiontracks),
        previousRequisitionTrackId: last(requisition?.requisitiontracks).ID,
        // isReservedUser,
      });
    } else {
      await ApproveOnlyDialog({
        authUser,
        requisitionTitle: requisition.title,
        requisition: last(requisition?.requisitiontracks),
        previousRequisitionTrackId: last(requisition?.requisitiontracks).ID,
        tableData,
        isReservedUser,
      });
    }
  };

  const pullDataFromString = ({ text, requisitionType, poVendor }) => {
    let res = "";
    if (
      lowerCase(requisitionType) === "fund request" ||
      lowerCase(requisitionType) === "retirement for fund request" ||
      lowerCase(requisitionType) === "budget"
    ) {
      res = text
        .split("&&&")
        .filter((el) => el)
        .map((el) => {
          const data = el.split(",,,");
          return {
            description: data[0],
            unitCost: Number(data[1]).toFixed(2),
            quantity: data[2],
            subtotal: data[1] * data[2],
          };
        })
        .filter((el) => el.description);
      // console.log(res);
      return res;
    } else if (lowerCase(requisitionType) === "inventory") {
      console.log(text);
      res = text
        .split("&&&")
        .filter((el) => el)
        .map((el) => {
          const data = el.split(",,,");
          return {
            barcode: data[0],
            itemName: data[1],
            unitCost: Number(data[2]).toFixed(2),
            quantity: data[3],
            subtotal: data[2] * data[3],
          };
        })
        .filter((el) => el.barcode);
      //   console.log(res);
      return res;
    } else if (lowerCase(requisitionType) === "material request") {
      // console.log(text);
      res = text
        .split("&&&")
        .filter((el) => el)
        .map((el) => {
          const data = el.split(",,,");

          return {
            barcode: data[0] ? data[0] : nanoid(5),
            itemName: data[1],
            description: data[2],
            quantityRequested: data[3],
            availability: data[4],
            quantityApproved: data[5],
          };
        })
        .filter((el) => el.barcode);
      return res;
    } else if (lowerCase(requisitionType) === "rfq") {
      const splitOne = text.split("&&&")[0].split(",,,");
      res = text
        .split("&&&")
        .filter((el) => el)
        .map((el) => {
          const data = el.split(",,,");
          let main = {};

          // console.log({ text, splitOne, data });
          if (splitOne?.length > 5) {
            main = {
              barcode: data[0],
              itemName: data[1],
              quantity: data[2],
              warehouse: data[3],
              Generic_Configuration: data[4],
              Branded_Generic: data[5],
              Pack_Size: data[6],
              Tier: data[7],
              specification: data[8],
            };
          } else if (splitOne?.length > 4) {
            main = {
              barcode: data[0],
              itemName: data[1],
              quantity: data[3],
              warehouse: data[2],
              ids: data[4],
              specification: "",
            };
          } else {
            main = {
              barcode: data[0],
              itemName: data[1],
              quantity: data[2],
              warehouse: data[3],
              specification: "",
            };
          }
          // console.log(main);
          return main;
        })
        .filter((el) => el.barcode);
      //   console.log(res);
      return res;
    } else if (lowerCase(requisitionType) === "po") {
      const splitOne = text.split("&&&")[0].split(",,,");

      res = text
        .split("&&&")
        .filter((el) => el)
        .map((el) => {
          const data = el.split(",,,");
          let main = {};

          if (splitOne.length < 12) {
            main = {
              barcode: data[1],
              itemName: data[2],
              unitCost: Number(data[6]).toFixed(2),
              quantity: data[7],
              subtotal: data[6] * data[7],
              requestid: id,
              vendor: poVendor?.VendorsQuotes?.Vendor_ID,
              RFQId: data[5],
              warehouse: data[4],
            };
          } else {
            main = {
              barcode: data[1],
              itemName: data[2],
              unitCost: Number(data[7]).toFixed(2),
              quantity: data[8],
              subtotal: data[7] * data[8],
              requestid: id,
              vendor: poVendor?.VendorsQuotes?.Vendor_ID,
              RFQId: data[4],
              warehouse: data[6],

              Generic_Configuration: data[11],
              Branded_Generic: data[12],
              Pack_Size: data[13],
              Tier: data[14],
              specification: data[16],
            };
          }
          // console.log(data, main,);
          return main;
        })
        .filter((el) => el.barcode);
      // console.log(res);
      return res;
    }

    /*res = text
      .replaceAll("SELECTED INVENTORY ITEMS", "")
      .replaceAll("Barcode:", "#_$_%")
      .replaceAll("Item Name:", "#_$_%")
      .replaceAll("Quantity:", "#_$_%")
      .replaceAll(/(\r\n|\n|\r)/gm, "")
      .replace(/\s+/g, "")
      .trim()
      .split("#_$_%")
      .filter((el) => el);

    res = chunk(res, 3).map((el) => ({
      Barcode: el[0],
      ItemName: el[1],
      Quantity: el[2],
    }));

    return res;*/
  };

  const handleInputChange = ({ index, name, value }) => {
    const oldTableData = tableData;
    oldTableData[index][name] = value;
    setTableData([...oldTableData]);
  };

  const calculateTotal = (tableData) => {
    setTotal(
      tableData
        .map((el) =>
          currency(el.unitCost, {
            precision: 2,
          }).multiply(el.quantity)
        )
        .reduce(
          (a, b) =>
            currency(a, {
              precision: 2,
            }).add(b),
          0
        )
    );
  };

  const debouncedTableData = useDebounce(tableData, 800);
  useEffect(() => {
    if (debouncedTableData) calculateTotal(debouncedTableData);
  }, [debouncedTableData]);

  const updateRequisition = async (payload) => {
    // console.log(payload);
    let formData = new FormData();

    // GET FILES FOR UPLOAD --------------------------------------
    if (payload.filledFormData) {
      payload.Template = payload.filledFormData;
      formData = formDataForFormBuider(payload, formData);
    }

    //------------------------------------------------------------
    for (const [key, value] of Object.entries(payload)) {
      formData.append(key, value);
    }

    let response = await fetch(`${backendUrl}/api/requisition/update/${id}`, {
      method: "POST",
      credentials: "include",
      body: formData,
    });

    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }

    const { data } = await response.json();
    return data;
  };

  const updateRequisitionMutation = useMutation(
    (payload) => updateRequisition(payload),
    {
      onSuccess: ({
        description,
        filledFormData,
        amount,
        initialExpenseAmount,
      }) => {
        queryClient.setQueryData(
          [requisitionActions.GET_REQUISITION_DETAILS, id],
          (data) => {
            data.requisition.description = description;
            data.requisition.filledFormData = filledFormData;
            data.requisition.amount = amount;
            if (typeof initialExpenseAmount != "undefined") {
              data.requisition.initialExpenseAmount = initialExpenseAmount;
            }

            sendNewSetupData(filledFormData);
            return data;
          }
        );
        // setFile(null);
        toast.success("Requisition updated successfully");
      },
      onError: () => {
        toast.error("Unable to save");
      },
    }
  );

  const save = () => {
    // if (!file) toast.error("Please attach your signature");
    let description = "";
    if (
      lowerCase(requisition?.type) === "fund request" ||
      lowerCase(requisition?.type) === "retirement for fund request" ||
      lowerCase(requisition?.type) === "budget"
    ) {
      description = [
        ...tableData,
        // add if form has data
        ...(fundRequest.description ? [fundRequest] : []),
      ]
        .filter((el) => el.description)
        .map((el) => {
          delete el.subtotal;
          return Object.values(el).join(",,,");
        })
        .join("&&&");
    } else if (lowerCase(requisition?.type) === "inventory") {
      description = tableData
        .map((el) => {
          delete el.subtotal;
          return Object.values(el).join(",,,");
        })
        .join("&&&");
    } else if (lowerCase(requisition?.type) === "material request") {
      // console.log(fundRequest);
      description = [
        ...tableData,
        // add if form has data
        ...(fundRequest?.itemName
          ? [{ ...fundRequest, barcode: nanoid() }]
          : []),
      ]
        .map((el) => {
          delete el.subtotal;
          return Object.values(el).join(",,,");
        })
        .join("&&&");
    } else if (lowerCase(requisition?.type) === "rfq") {
      description = tableData
        .map((el) => {
          delete el.subtotal;
          return Object.values(el).join(",,,");
        })
        .join("&&&");
    } else if (lowerCase(requisition?.type) === "po") {
      description = tableData
        .map((el) => {
          delete el.subtotal;
          return Object.values(el).join(",,,");
        })
        .join("&&&");
    }

    description = description + "&&&";
    const realTotal = currency(fundRequest.unitCost)
      .multiply(fundRequest.quantity)
      .add(total).value;
    updateRequisitionMutation.mutate({
      requestid: id,
      description,
      file,
      amount: isNaN(realTotal) ? 0 : realTotal,
      ...(!requisition.paymentType
        ? { initialExpenseAmount: isNaN(realTotal) ? 0 : realTotal }
        : {}),
      ...formik.values,
    });
  };

  const saveLoanAmount = async () => {
    try {
      await fetchActionsUtil(
        `${backendUrl}/api/requisition/update-amount/${id}`,
        "PUT",
        "",
        { amount: loanAmount }
      );
      toast.success("Amount Successfully Updated");
    } catch {
      toast.error("Unable to update");
    }
  };

  const [show, setShow] = useState(false);
  const [target, setTarget] = useState(null);
  const [activeTableIndex, setActiveTableIndex] = useState(null);
  const ref = useRef(null);

  const handleRowClick = (e, index) => {
    setShow(true);
    setTarget(e.target);
    //  setActiveTableIndex(index);
  };

  const createNew = (e) => {
    setShow(true);
    setTarget(e.target);
    setActiveTableIndex(-1);
  };

  const handleSelectedItem = (item) => {
    // console.log(item);
    /* Bar_Code: "0003";
    Branch: "HQ";
    Cat_Name: "A2 Lubricant";
    Item_Name: "A2 Oil 1L";
    Quantity: 12;
    UnitCost: 1450;
    UnitPrice: 1650;
    imagepath: ""; */

    let oldTableData = tableData;

    if (lowerCase(requisition?.type) === "inventory") {
      item = {
        barcode: item.Bar_Code,
        itemName: item.Item_Name,
        unitCost: item.UnitCost,
        quantity: 1,
      };
    } else if (lowerCase(requisition?.type) === "material request") {
      item = {
        barcode: item.Bar_Code,
        itemName: item.Item_Name,
        description: "",
        quantityRequested: "",
        availability: "",
        quantityApproved: "",
      };
    } else if (lowerCase(requisition?.type) === "fund request") {
    } else if (lowerCase(requisition?.type) === "rfq") {
      item = {
        barcode: item.Bar_Code,
        itemName: item.Item_Name,
        quantity: 1,
        warehouse: item?.Warehouse ? item?.Warehouse : " ",
        Generic_Configuration: item?.Generic_Configuration || " ",
        Branded_Generic: item?.Branded_Generic || " ",
        Pack_Size: item?.Pack_Size || " ",
        Tier: item?.Tier || " ",
        specification: item?.specification || " ",
      };

      const found = oldTableData.find((el) => el.barcode === item.barcode);

      if (found) {
        const newItem = { ...found, quantity: parseInt(found.quantity) + 1 };
        const index = oldTableData.findIndex(
          (el) => el.barcode === item.barcode
        );
        oldTableData[index] = newItem;
      } else {
        oldTableData = [...oldTableData, item];
      }
    }

    if (lowerCase(requisition?.type) !== "rfq") {
      if (activeTableIndex === -1) {
        oldTableData = [...oldTableData, item];
      } else {
        oldTableData[activeTableIndex] = item;
      }
    }

    setTableData([...oldTableData]);
    if (activeTableIndex === -1) setActiveTableIndex(oldTableData.length - 1);
    setShow(false);
  };

  const removeSelection = () => {
    const oldTableData = tableData.filter(
      (el, index) => index !== activeTableIndex
    );
    setTableData([...oldTableData]);
  };

  const handleFundRequestInput = (value, name) => {
    //  console.log(value, name);
    setFundRequest({
      ...fundRequest,
      [name]: value,
    });
  };

  const createNewFundRequest = (e) => {
    if (!e.target.value) return;
    setTableData([...tableData, fundRequest]);
    e.target.blur();

    setTimeout(() => {
      setFundRequest({ ...initialRequestData(requisition) });
    }, 10);
  };

  const addNewFundRequest = () => {
    setTableData([...tableData, fundRequest]);

    setTimeout(() => {
      setFundRequest({ ...initialRequestData(requisition) });
    }, 5);
  };

  const canSendTo = useMemo(
    () =>
      uniq(
        requisition?.requisitiontracks
          .filter(
            (el) =>
              el.status === "Approved" || el.status === "Approved & Closed"
          )
          .map((el) => el.receivedby)
      ).includes(authUser.Staff_ID),
    [requisition, authUser]
  );

  const canReservedUserSend = useMemo(() => {
    if (!requisition) return false;

    const numberOfMandatoryApprovalsBeforeApproveAndClose = Number(
      generalSettings.numberOfMandatoryApprovalsBeforeApproveAndClose
    );

    // Reserved Users - Mandatory Signatories
    const reservedUsers = generalSettings.fundRequestMandatorySignatory.map(
      (el) => el.staff
    );

    const usersThatHaveApproved = uniq(
      requisition?.requisitiontracks.map((el) => {
        return el.status === "Approved" ? el.receivedby : el.sentby;
      })
    );

    // How many mandatory has Approved
    const howManyMandatoryUserHasApproved = size(
      usersThatHaveApproved.filter((el) => reservedUsers.includes(el))
    );

    // Has User Approved Before
    const hasUserApproved = usersThatHaveApproved.find(
      (el) => el === authUser.Staff_ID
    );

    return (
      !hasUserApproved &&
      Number(howManyMandatoryUserHasApproved) <
      Number(numberOfMandatoryApprovalsBeforeApproveAndClose) &&
      reservedUsers.includes(authUser?.Staff_ID) &&
      requisition.type === "Fund Request" &&
      !requisition.status.includes("Approved & Closed") &&
      !requisition.status.includes("Disapproved")
    );
  }, [requisition]);

  const canReservedUserPerformAction = useMemo(() => {
    if (!requisition) return false;
    const numberOfMandatoryApprovalsBeforeApproveAndClose = Number(
      generalSettings.numberOfMandatoryApprovalsBeforeApproveAndClose
    );

    // Reserved Users - Mandatory Signatories
    const reservedUsers = generalSettings.fundRequestMandatorySignatory.map(
      (el) => el.staff
    );

    const usersThatHaveApproved = uniq(
      requisition?.requisitiontracks.map((el) => {
        return el.status === "Approved" || el.status === "Approved & Closed"
          ? el.receivedby
          : el.sentby;
      })
    );

    // How many mandatory has Approved
    const howManyMandatoryUserHasApproved = size(
      usersThatHaveApproved.filter((el) => reservedUsers.includes(el))
    );

    // Has User Approved Before
    const hasUserApproved = usersThatHaveApproved.find(
      (el) => el === authUser.Staff_ID
    );

    /*  console.log(
      !hasUserApproved,
      Number(numberOfMandatoryApprovalsBeforeApproveAndClose) ===
        Number(howManyMandatoryUserHasApproved),
      reservedUsers.includes(authUser?.Staff_ID),
      requisition.type === "Fund Request",
      !requisition.status.includes("Approved & Closed"),
      !requisition.status.includes("Disapproved")
    ); */

    return (
      !hasUserApproved &&
      Number(numberOfMandatoryApprovalsBeforeApproveAndClose) ===
      Number(howManyMandatoryUserHasApproved) &&
      reservedUsers.includes(authUser?.Staff_ID) &&
      requisition.type === "Fund Request" &&
      !requisition.status.includes("Approved & Closed") &&
      !requisition.status.includes("Disapproved")
    );
  }, [requisition]);

  const canPerformAction = (requisitionData) => {
    if (!requisition) return false;
    const pendingRequisition = last(requisitionData?.requisitiontracks);

    // By Department
    if (
      pendingRequisition?.sentToDepartment === authUser.Department &&
      pendingRequisition?.sentby !== authUser.Staff_ID &&
      pendingRequisition?.status === "Pending"
    ) {
      return true;
    }

    // console.log(pendingRequisition);
    return (
      pendingRequisition &&
      pendingRequisition?.status === "Pending" &&
      pendingRequisition?.receivedby === authUser.Staff_ID
    );
  };

  const canModifyFundRequest = (requisition) => {
    return (
      ["fund request"].includes(lowerCase(requisition?.type)) &&
      (authUser.Staff_ID === requisition?.requestby || isCashier)
    );
  };

  /*   const handleFileInput = (e) => {
    e.preventDefault();
    let fileInput = e.target;
    setFile(fileInput.files[0]);
  };

  const download = () => {
    const link = document.createElement("a");
    link.setAttribute("download", `${requisition.requestid}.png`);
    link.href = `${backendUrl}/api/requisition/pdf/${
      requisition.requestid
    }?download=true`;
    document.body.appendChild(link);
    link.click();
    link.remove();
  }; */

  const sendSetupData = () => {
    const iframe = iframeRef.current;
    if (iframe && iframe.contentWindow) {
      iframe.contentWindow.postMessage(
        {
          data: {
            departments: [],
            template: JSON.parse(requisition.filledFormData),
            backendUrl,
            companyData: currentLoggedInCompany,
            formInfo: {
              formNumber: formatNumberSystem(
                requisition.numberPrefix,
                requisition.currentNumber
              ),
              documentNumber: requisition?.documentNumber,
              revision: requisition.revision,
              date: formatDate(requisition.regdate, "MMM yyyy"),
            },
          },
        },
        process.env.REACT_APP_FORM_BUILDER_URL
      );
    }
  };

  const sendNewSetupData = (filledFormData) => {
    const iframe = iframeRef.current;
    if (iframe && iframe.contentWindow) {
      iframe.contentWindow.postMessage(
        {
          data: {
            departments: [],
            template: JSON.parse(filledFormData),
            backendUrl,
            companyData: currentLoggedInCompany,
            formInfo: {
              formNumber: formatNumberSystem(
                requisition.numberPrefix,
                requisition.currentNumber
              ),
              documentNumber: requisition?.documentNumber,
              revision: requisition.revision,
              date: formatDate(requisition.regdate, "MMM yyyy"),
            },
          },
        },
        process.env.REACT_APP_FORM_BUILDER_URL
      );
    }
  };

  const saveForm = () => {
    const iframe = iframeRef.current;
    if (iframe && iframe.contentWindow) {
      iframe.contentWindow.postMessage(
        {
          type: "SUBMIT",
        },
        process.env.REACT_APP_FORM_BUILDER_URL
      );
    }
  };

  const printForm = (orientation) => {
    const iframe = iframeRef.current;
    if (iframe && iframe.contentWindow) {
      if (orientation) {
        iframe.contentWindow.postMessage(
          {
            type: "OREINTATION",
            data: {
              orientation,
            },
          },
          process.env.REACT_APP_FORM_BUILDER_URL
        );

        //  Delay -------------

        setTimeout(() => {
          iframe.contentWindow.postMessage(
            {
              type: "PRINT",
            },
            process.env.REACT_APP_FORM_BUILDER_URL
          );
        }, 500);

        //-------------------
      } else {
        iframe.contentWindow.postMessage(
          {
            type: "PRINT",
          },
          process.env.REACT_APP_FORM_BUILDER_URL
        );
      }
    }
  };

  const setOrientation = (printOrientation) => {
    const iframe = iframeRef.current;
    if (iframe && iframe.contentWindow) {
      iframe.contentWindow.postMessage(
        {
          type: "OREINTATION",
          data: {
            ...printOrientation,
          },
        },
        process.env.REACT_APP_FORM_BUILDER_URL
      );
    }
  };

  const handleMessage = (message) => {
    if (message.data.type === "CLOSE") {
      // setShowNewCustomRequisitionModal(false);
    } else if (message.data.type === "SAVE") {
      updateRequisitionMutation.mutate({
        requestid: id,
        filledFormData: message.data.value,
      });
    }
  };

  useEffect(() => {
    window.addEventListener("message", handleMessage);

    // cleanup this component
    return () => {
      window.removeEventListener("message", handleMessage);
    };
  }, []);

  eventBus.useCustomEventListener(
    "SEND_FILE_TO_IFRAME",
    ({ files, elementId, elementType }) => {
      const iframe = iframeRef.current;
      if (iframe && iframe.contentWindow) {
        iframe.contentWindow.postMessage(
          {
            data: {
              files,
              elementId,
              elementType,
            },
            type: "FILES_FROM_PARENT",
          },
          process.env.REACT_APP_FORM_BUILDER_URL
        );
      }
    }
  );

  eventBus.useCustomEventListener("REQUISITION_UPDATED", (requisition) => {
    if (requisition?.filledFormData) {
      const iframe = iframeRef.current;
      if (iframe && iframe.contentWindow) {
        iframe.contentWindow.postMessage(
          {
            data: {
              template: JSON.parse(requisition.filledFormData),
            },
          },
          process.env.REACT_APP_FORM_BUILDER_URL
        );
      }

      queryClient.setQueryData(
        [requisitionActions.GET_REQUISITION_DETAILS, id],
        (data) => {
          data.requisition.filledFormData = requisition?.filledFormData;
          return data;
        }
      );
    }
  });

  const currencySymbol = useMemo(() => {
    const foundCurrency = currenciesOptions.find(
      (el) =>
        el.cc ===
        (requisition?.currency
          ? requisition?.currency
          : generalSettings?.prevailingCurrency)
    );
    return foundCurrency ? foundCurrency.symbol : "";
  }, [requisition?.currency]);

  const commentOnRequisition = async () => {
    await LeaveCommentDialog({
      requisition,
      //     refetch,
    });
  };

  const getPoItems = () => {
    // GET ITEMS FULL DATA

    setShowNewPoRequisitionModal(true);
  };

  const uploadFileApi = async (payload) => {
    const formData = new FormData();
    const alreadyUploadedFilesInitialFiles = [];
    for (const file of payload.initialFiles) {
      if (!file?.fileId) {
        formData.append("initialFiles", file);
      } else {
        alreadyUploadedFilesInitialFiles.push(file);
      }
    }

    if (!isEmpty(alreadyUploadedFilesInitialFiles)) {
      formData.append(
        "alreadyUploadedFilesInitialFiles",
        JSON.stringify(alreadyUploadedFilesInitialFiles)
      );
    }
    const url = ["rfq", "po"].includes(lowerCase(requisition?.type))
      ? `${backendUrl}/api/vendors/upload-files`
      : `${backendUrl}/api/requisition/upload-files/${requisition.requestid}`;

    let response = await fetch(url, {
      method: "POST",
      body: formData,
    });
    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }
    const res = await response.json();
    return res;
  };

  const uploadFileMutation = useMutation((payload) => uploadFileApi(payload), {
    onSuccess: async ({ message, data, imageFiles = [] }) => {
      toast.success(message);
      ["rfq", "po"].includes(lowerCase(requisition?.type)) &&
        setAttachments(imageFiles);
      refetch();
    },
    onError: () => {
      toast.error(`Unable to perform action`);
    },
  });

  const sendDeleteImage = async (payload, index) => {
    try {
      setIsLoading(true);
      let response = await fetch(
        `${backendUrl}/api/${["rfq", "po"].includes(lowerCase(requisition?.type))
          ? "vendors"
          : "requisition"
        }/delete-initial-file`,
        {
          method: "POST",
          credentials: "include",
          body: JSON.stringify(payload),
          headers: {
            Accept: "Application/json",
            "Content-Type": "Application/json",
          },
        }
      );
      if (!response.ok) {
        response = await response.json();
        throw new Error(response.message);
      }
      const res = await response.json();
      setAttachments(attachments.filter((el, i) => i !== index));

      return res;
    } catch (err) {
      console.log(err);
    } finally {
      setIsLoading(false);
    }
  };

  const deleteImage = async (index) => {
    const file = attachments.find((el, i) => i === index);
    if (file?.fileId) {
      if (
        await ConfirmDialog({
          title: "Delete",
          description: `Do you want to delete this file - ${file.name
            }, you can't undo this`,
        })
      ) {
        const res = await sendDeleteImage(
          { file, requestid: requisition.requestid },
          index
        );
        toast.success(res?.message);
      }
    } else {
      setAttachments(attachments.filter((el, i) => i !== index));
    }
  };

  const preSelectedSignatoryData = useMemo(() => {
    try {
      if (requisition?.preSelectedSignatory) {
        return JSON.parse(requisition?.preSelectedSignatory);
      }
      return [];
    } catch (err) {
      console.log(err);
      return [];
    }
  }, [requisition?.preSelectedSignatory]);

  return (
    <div>
      <Modal
        show={true}
        onHide={() => goBack("/requisition/request")}
        dialogClassName={`requisition-details-modal preview-modal ${orientation === "Landscape" ? "modal-90w" : ""
          }`}
        backdropClassName={`global-backdrop`}
        centered={true}
        animation={false}
        enforceFocus={false}
        fullscreen={styleHelper.isMobile}
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <h1>{company?.CompName || "..."}</h1>
            <p>{company?.AddressLine1 || "..."}</p>{" "}
            <p>
              {formatNumberSystem(
                requisition?.numberPrefix,
                requisition?.currentNumber
              )}
            </p>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="preview p-0">
          <div className="preview-info p-4 pt-0 d-flex justify-content-between">
            <p className=" d-flex gap-3 title">
              <span>From:</span>
              <span className="fw-5">
                {requisition ? (
                  requisition.type === "Loan" ||
                    requisition.type === "Permission" ? (
                    <a
                      href={`${HRFrontEndUrl}/dashboard/profile/${JSON.parse(requisition.loanDetails).employeeId
                        }`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {JSON.parse(requisition.loanDetails).employeeName}
                    </a>
                  ) : (
                    getUserThatRequestedToMe({
                      requisitionTracks: requisition?.requisitiontracks,
                      receivedby: authUser?.Staff_ID,
                    })
                  )
                ) : (
                  "..."
                )}
              </span>
            </p>

            <div className="d-flex action-btns d-print-none">
              <Button onClick={() => refetch()} variant="light">
                <CachedIcon />
              </Button>
              {requisition?.filledFormData ? (
                <Button
                  className="btn d-flex align-items-center justify-content-center"
                  title="Print"
                  variant="light"
                  onClick={() => printForm()}
                >
                  <PrintIcon />
                </Button>
              ) : (
                <>
                  <a
                    href={`${backendUrl}/api/requisition/pdf/${requisition?.requestid
                      }`}
                    className="btn d-flex align-items-center justify-content-center"
                    title="Print"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <PrintIcon />
                  </a>
                  <Button
                    onClick={() =>
                      copyText(
                        `${backendUrl}/api/requisition/pdf/${requisition?.requestid
                        }`,
                        "PDF Link Copied"
                      )
                    }
                    variant="light"
                  >
                    <ShareVariantIcon />
                  </Button>
                </>
              )}
            </div>
          </div>
          <div className="preview-content border-bottom">
            <div className="content">
              <div className="preview-header border-bottom mb-3">
                {/* <Logo /> */}
                <img
                  width={50}
                  src={`${backendUrl}/images/company-logo.png`}
                  alt="logo"
                />

                <span>
                  Date:{" "}
                  {requisition?.regdate
                    ? format(new Date(requisition?.regdate), "eee MMMM do yyyy")
                    : "..."}
                </span>
              </div>
              <div className="preview-body">
                <div className="d-flex justify-content-between">
                  <div>
                    <h2>
                      {requisition?.title}{" "}
                      {requisition?.jobNumber && requisition?.type !== "Budget"
                        ? ` / ${requisition?.jobNumber || ""} - ${requisition
                          ?.job?.title || ""}`
                        : ``}
                    </h2>
                    <p>
                      {requisition?.type === "Loan" ? (
                        <ApprovalBadge
                          text={requisition?.type}
                          // className="approval"
                          className="loanstyle"
                        />
                      ) : (
                        requisition?.type
                      )}
                    </p>
                  </div>
                  {po?.VendorsQuotes?.CompanyName && (
                    <div>
                      <b>Vendor : {po?.VendorsQuotes?.CompanyName}</b>
                    </div>
                  )}
                  <div>
                    {lowerCase(requisition?.type) === "salary" && (
                      <p className="fs-4 fw-bold mb-2">
                        {currency(salaryAmount ? salaryAmount : 0, {
                          precision: 2,
                          symbol: "",
                        }).format()}
                      </p>
                    )}

                    {lowerCase(requisition?.status) === "approved closed" &&
                      (lowerCase(requisition?.type) === "loan" ||
                        lowerCase(requisition?.type) === "salary") ? (
                      <div className="mx-3">
                        <p className="text-danger fs-4 fw-bold mb-2">Unpaid</p>
                        <button
                          className="btn btn-success text-white fs-6 px-4"
                          onClick={() => setOpenAccountModal(true)}
                        >
                          Pay
                        </button>
                      </div>
                    ) : (
                      lowerCase(requisition?.status) === "paid" && (
                        <p className="text-success fs-4 fw-bold mx-3">Paid</p>
                      )
                    )}
                    {lowerCase(requisition?.type) === "permission" && (
                      <p className="fw-bold mx-3">{`${JSON.parse(requisition.loanDetails)?.type
                        } from ${JSON.parse(requisition.loanDetails)?.startDate
                        } - ${JSON.parse(requisition.loanDetails)?.endDate}`}</p>
                    )}

                    {lowerCase(requisition?.type) === "salary" && (
                      <div className="d-flex my-4">
                        <a
                          className="btn btn-primary"
                          href={`${HRFrontEndUrl}/dashboard/salary-summary?month=${salaryMonth}`}
                          target="_blank"
                          rel="noreferrer"
                        >
                          Salary Summary
                        </a>
                        <a
                          className="btn btn-primary mx-4"
                          href={`${HRFrontEndUrl}/dashboard/pay-history?month=${salaryMonth}`}
                          target="_blank"
                          rel="noreferrer"
                        >
                          Payment Summary
                        </a>
                      </div>
                    )}
                  </div>
                </div>
                {![
                  "inventory",
                  "fund request",
                  "material request",
                  "budget",
                  "rfq",
                  "po",
                  "permission",
                  "retirement for fund request",
                ].includes(lowerCase(requisition?.type)) && (
                    <p className="mb-md-3">{requisition?.description}</p>
                  )}
                <>
                  <Table borderless className="form-table">
                    <tbody className="d-md-flex flex-wrap">
                      <tr className="d-flex flex-column w-50">
                        <td>Department:</td>
                        <td>{requisition?.requestbyUser?.Department}</td>
                      </tr>

                      <tr className="d-flex flex-column w-50">
                        <td>Requester:</td>
                        <td>
                          {requisition?.requestbyUser?.Name ||
                            requisition?.sentByName}
                        </td>
                      </tr>

                      {requisition?.vendor && (
                        <tr className="d-flex flex-column w-50">
                          <td> Vendor:</td>
                          <td>{requisition?.vendor}</td>
                        </tr>
                      )}

                      {requisition?.requiredSignatoryStaff_ID && (
                        <tr className="d-flex flex-column w-50">
                          <td> Mandatory Signatory:</td>
                          <td>{requisition.requiredUser?.Name}</td>
                        </tr>
                      )}
                    </tbody>
                  </Table>
                </>
                {lowerCase(requisition?.type) === "loan" && (
                  <>
                    <Table
                      responsive
                      borderless
                      striped
                      className="product-table with-large-textarea"
                    >
                      <tbody>
                        <tr>
                          <td className="fs-6 fw-bold">Amount</td>
                          <td>
                            <CurrencyInput
                              className="form-control border-0 px-1 fw-bold"
                              value={loanAmount}
                              onKeyDown={(e) => {
                                e.keyCode === 13 && e.target.blur();
                                e.key === "Enter" && saveLoanAmount();
                              }}
                              name="loanamount"
                              onValueChange={(value, name) =>
                                setLoanAmount(value)
                              }
                              onEnter
                              decimalsLimit={2}
                              disabled={
                                // lowerCase(requisition?.status) ===
                                //   "approved closed" ||
                                lowerCase(requisition?.status) === "paid"
                              }
                            />
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </>
                )}
                {lowerCase(requisition?.type) === "material request" ? (
                  <>
                    <hr />
                    <Form.Group className="mb-3">
                      <Form.Label>Project Description :</Form.Label>
                      <label className="border p-0 rounded message-area d-block">
                        <Form.Control
                          name="projectDescription"
                          value={formik.values.projectDescription}
                          onChange={formik.handleChange}
                          as="textarea"
                          className="w-100 border-0 p-3"
                          placeholder="Enter a Project  Description"
                          rows={4}
                        />
                      </label>
                    </Form.Group>
                    <Table
                      responsive
                      borderless
                      striped
                      className="product-table with-large-textarea"
                    >
                      <thead>
                        <tr>
                          <th>S/N</th>
                          <th>Items/Materials</th>
                          <th>Specifications</th>
                          <th>Quantity Requested</th>
                          <th>Availability</th>
                          <th>Quantity Approved</th>
                        </tr>
                      </thead>

                      <tbody>
                        {tableData &&
                          tableData.map((el, index) => (
                            <tr
                              key={index}
                              className={`${activeTableIndex === index &&
                                "active-row"}`}
                              onClick={() =>
                                activeTableIndex !== index &&
                                setActiveTableIndex(index)
                              }
                            >
                              <td>{index + 1}</td>
                              <td>
                                <input
                                  className="form-control border-0 px-1 bg-white"
                                  value={el.itemName}
                                  onKeyDown={(e) =>
                                    e.keyCode === 13 && e.target.blur()
                                  }
                                  onChange={(e) =>
                                    handleInputChange({
                                      index,
                                      name: "itemName",
                                      value: e.target.value,
                                    })
                                  }
                                />
                              </td>{" "}
                              <td>
                                <input
                                  className="form-control border-0 px-1 bg-white"
                                  value={el.description}
                                  onKeyDown={(e) =>
                                    e.keyCode === 13 && e.target.blur()
                                  }
                                  onChange={(e) =>
                                    handleInputChange({
                                      index,
                                      name: "description",
                                      value: e.target.value,
                                    })
                                  }
                                />
                              </td>
                              <td>
                                <input
                                  className="form-control border-0 px-1 bg-white"
                                  value={el.quantityRequested}
                                  onKeyDown={(e) =>
                                    e.keyCode === 13 && e.target.blur()
                                  }
                                  onChange={(e) =>
                                    handleInputChange({
                                      index,
                                      name: "quantityRequested",
                                      value: e.target.value,
                                    })
                                  }
                                />
                              </td>
                              <td>
                                <input
                                  className="form-control border-0 px-1 bg-white"
                                  value={el.availability}
                                  onKeyDown={(e) =>
                                    e.keyCode === 13 && e.target.blur()
                                  }
                                  onChange={(e) =>
                                    handleInputChange({
                                      index,
                                      name: "availability",
                                      value: e.target.value,
                                    })
                                  }
                                />
                              </td>
                              <td>
                                <input
                                  className="form-control border-0 px-1 bg-white"
                                  value={el.quantityApproved}
                                  onKeyDown={(e) =>
                                    e.keyCode === 13 && e.target.blur()
                                  }
                                  onChange={(e) =>
                                    handleInputChange({
                                      index,
                                      name: "quantityApproved",
                                      value: e.target.value,
                                    })
                                  }
                                />
                              </td>
                            </tr>
                          ))}

                        <tr
                          className={`bg-light p-cursor ${activeTableIndex ===
                            -1 && "active-row"}`}
                        /* onClick={() =>
                      activeTableIndex !== -1 && setActiveTableIndex(-1)
                    } */
                        >
                          <td onClick={(e) => createNew(e)}>
                            <span className="">
                              <MagnifyIcon />
                            </span>
                          </td>

                          <td>
                            <input
                              className="form-control border-0 px-1 bg-white"
                              name="itemName"
                              value={fundRequest.itemName}
                              onKeyDown={(e) =>
                                e.keyCode === 13 && createNewFundRequest(e)
                              }
                              onChange={(e) =>
                                handleFundRequestInput(
                                  e.target.value,
                                  e.target.name
                                )
                              }
                            />
                          </td>
                          <td>
                            <Form.Control
                              as="textarea"
                              rows={3}
                              className="form-control border-0 px-1 bg-white col-6"
                              name="description"
                              value={fundRequest.description}
                              onKeyDown={(e) =>
                                e.keyCode === 13 && createNewFundRequest(e)
                              }
                              onChange={(e) =>
                                handleFundRequestInput(
                                  e.target.value,
                                  e.target.name
                                )
                              }
                            />
                          </td>

                          <td>
                            <input
                              className="form-control border-0 px-1 bg-white"
                              name="quantityRequested"
                              value={fundRequest.quantityRequested}
                              onKeyDown={(e) =>
                                e.keyCode === 13 && createNewFundRequest(e)
                              }
                              onChange={(e) =>
                                handleFundRequestInput(
                                  e.target.value,
                                  e.target.name
                                )
                              }
                            />
                          </td>
                          <td>
                            <input
                              className="form-control border-0 px-1 bg-white"
                              name="availability"
                              value={fundRequest.availability}
                              onKeyDown={(e) =>
                                e.keyCode === 13 && createNewFundRequest(e)
                              }
                              onChange={(e) =>
                                handleFundRequestInput(
                                  e.target.value,
                                  e.target.name
                                )
                              }
                            />
                          </td>
                          <td>
                            <input
                              className="form-control border-0 px-1 bg-white"
                              name="quantityApproved"
                              value={fundRequest.quantityApproved}
                              onKeyDown={(e) =>
                                e.keyCode === 13 && createNewFundRequest(e)
                              }
                              onChange={(e) =>
                                handleFundRequestInput(
                                  e.target.value,
                                  e.target.name
                                )
                              }
                            />
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                    <Form.Group className="col-4 mb-3 pb-2  mt-2">
                      <Form.Label className="mb-1">
                        Expected Delivery Date
                      </Form.Label>
                      <Datetime
                        dateFormat="MMM DD, YYYY"
                        timeFormat={false}
                        closeOnSelect={true}
                        closeOnClickOutside={true}
                        name="expectedDeliveryDate"
                        inputProps={{
                          className: `date-input form-control ${formik.touched.expectedDeliveryDate &&
                            !!formik.errors.expectedDeliveryDate
                            ? "is-invalid"
                            : ""
                            }`,
                          placeholder: "Select date",
                          readOnly: true,
                        }}
                        value={formik.values.expectedDeliveryDate}
                        onChange={(date) => {
                          formik.setFieldValue(
                            "expectedDeliveryDate",
                            date,
                            true
                          );
                        }}
                        onBlur={() =>
                          formik.setFieldTouched("expectedDeliveryDate", true)
                        }
                      />
                      {formik.touched.expectedDeliveryDate &&
                        !!formik.errors.expectedDeliveryDate ? (
                        <span className="custom-invalid-feedback">
                          {formik.errors.expectedDeliveryDate}
                        </span>
                      ) : null}
                    </Form.Group>
                  </>
                ) : lowerCase(requisition?.type) === "inventory" ? (
                  <Table
                    responsive
                    borderless
                    striped
                    className="product-table with-large-textarea"
                  >
                    <thead>
                      <tr>
                        <th>S/N</th>
                        <th>Item Code</th>
                        <th>Item Name</th>
                        <th>Unit Cost</th>
                        <th>Quantity</th>
                        <th>Subtotal</th>
                      </tr>
                    </thead>
                    <tbody ref={ref}>
                      {tableData &&
                        tableData.map((el, index) => (
                          <tr
                            style={
                              !canPerformAction(requisition)
                                ? { pointerEvents: "none" }
                                : {}
                            }
                            key={index}
                            className={`${activeTableIndex === index &&
                              "active-row"}`}
                            onClick={() =>
                              activeTableIndex !== index &&
                              setActiveTableIndex(index)
                            }
                          >
                            <td>{index + 1}</td>
                            <td
                              className="p-cursor"
                              onClick={(e) => handleRowClick(e, index)}
                            >
                              {el.barcode}
                            </td>
                            <td>{el.itemName}</td>
                            <td>
                              <CurrencyInput
                                className="form-control border-1 px-1"
                                value={el.unitCost}
                                onKeyDown={(e) =>
                                  e.keyCode === 13 && e.target.blur()
                                }
                                name="unitCost"
                                onValueChange={(value, name) =>
                                  handleInputChange({
                                    index,
                                    name,
                                    value,
                                  })
                                }
                                // disableGroupSeparators
                                decimalsLimit={2}
                              />{" "}
                            </td>
                            <td>
                              <CurrencyInput
                                className="form-control border-1 px-1"
                                value={el.quantity}
                                onKeyDown={(e) =>
                                  e.keyCode === 13 && e.target.blur()
                                }
                                name="quantity"
                                onValueChange={(value, name) =>
                                  handleInputChange({
                                    index,
                                    name,
                                    value,
                                  })
                                }
                                // disableGroupSeparators
                                allowNegativeValue={false}
                                allowDecimals={true}
                              />
                            </td>
                            <td>
                              <CurrencyInput
                                className="form-control border-1 px-0"
                                name="subtotal"
                                disabled
                                value={
                                  !isNaN(el.unitCost * el.quantity)
                                    ? currency(el.unitCost, {
                                      precision: 2,
                                    }).multiply(el.quantity)
                                    : 0
                                }
                                onKeyDown={(e) =>
                                  e.keyCode === 13 && e.target.blur()
                                }
                                onValueChange={(value, name) =>
                                  handleInputChange({
                                    index,
                                    name,
                                    value,
                                  })
                                }
                                // // disableGroupSeparators
                                decimalsLimit={2}
                              />
                            </td>
                          </tr>
                        ))}
                      <tr
                        style={
                          !canPerformAction(requisition)
                            ? { pointerEvents: "none" }
                            : {}
                        }
                        className={`p-cursor ${activeTableIndex === -1 &&
                          "active-row"}`}
                      >
                        <td onClick={(e) => createNew(e)}>
                          <span className="">
                            <MagnifyIcon />
                          </span>
                        </td>
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                      </tr>
                    </tbody>
                  </Table>
                ) : lowerCase(requisition?.type) === "rfq" ? (
                  <Table
                    responsive
                    borderless
                    striped
                    className="product-table with-large-textarea"
                  >
                    <thead>
                      <tr>
                        <th>S/N</th>
                        <th>Item Code</th>
                        <th>Item Name</th>
                        <th>Specification</th>
                        <th>Quantity</th>
                      </tr>
                    </thead>
                    <tbody ref={ref}>
                      {tableData &&
                        tableData.map((el, index) => (
                          <tr
                            style={
                              !canPerformAction(requisition)
                                ? { pointerEvents: "none" }
                                : {}
                            }
                            key={index}
                            className={`${activeTableIndex === index &&
                              "active-row"}`}
                            onClick={() =>
                              activeTableIndex !== index &&
                              setActiveTableIndex(index)
                            }
                          >
                            <td>{index + 1}</td>
                            <td
                              className="p-cursor"
                              onClick={(e) => handleRowClick(e, index)}
                            >
                              {el.barcode}
                            </td>
                            <td>{el.itemName}</td>

                            <td>
                              <Form.Control
                                as="textarea"
                                rows={3}
                                className="form-control border-0 px-1 bg-white col-6"
                                name="specification"
                                value={el?.specification}
                                onKeyDown={(e) =>
                                  e.keyCode === 13 && e.target.blur()
                                }
                                onChange={(e) =>
                                  handleInputChange({
                                    index,
                                    name: "specification",
                                    value: e.target.value,
                                  })
                                }
                              />
                            </td>

                            {/* <td>
                              <CurrencyInput
                                className="form-control border-1 px-1"
                                value={el.unitCost}
                                onKeyDown={(e) =>
                                  e.keyCode === 13 && e.target.blur()
                                }
                                name="unitCost"
                                onValueChange={(value, name) =>
                                  handleInputChange({
                                    index,
                                    name,
                                    value,
                                  })
                                }
                                // disableGroupSeparators
                                decimalsLimit={2}
                              />{" "}
                            </td> */}
                            <td>
                              <CurrencyInput
                                className="form-control border-1 px-1"
                                value={el.quantity}
                                onKeyDown={(e) =>
                                  e.keyCode === 13 && e.target.blur()
                                }
                                name="quantity"
                                onValueChange={(value, name) =>
                                  handleInputChange({
                                    index,
                                    name,
                                    value,
                                  })
                                }
                                // disableGroupSeparators
                                allowNegativeValue={false}
                                allowDecimals={true}
                              />
                            </td>

                            {/* <td>
                              <CurrencyInput
                                className="form-control border-1 px-0"
                                name="subtotal"
                                disabled
                                value={
                                  !isNaN(el.unitCost * el.quantity)
                                    ? currency(el.unitCost, {
                                        precision: 2,
                                      }).multiply(el.quantity)
                                    : 0
                                }
                                onKeyDown={(e) =>
                                  e.keyCode === 13 && e.target.blur()
                                }
                                onValueChange={(value, name) =>
                                  handleInputChange({
                                    index,
                                    name,
                                    value,
                                  })
                                }
                                // // disableGroupSeparators
                                decimalsLimit={2}
                              />
                            </td> */}
                          </tr>
                        ))}
                      <tr
                        style={
                          !canPerformAction(requisition)
                            ? { pointerEvents: "none" }
                            : {}
                        }
                        className={`p-cursor ${activeTableIndex === -1 &&
                          "active-row"}`}
                      >
                        <td onClick={(e) => createNew(e)}>
                          <span className="">
                            <MagnifyIcon />
                          </span>
                        </td>
                        <td />
                        <td />
                        <td />
                      </tr>
                    </tbody>
                  </Table>
                ) : lowerCase(requisition?.type) === "po" ? (
                  <Table
                    responsive
                    borderless
                    striped
                    className="product-table with-large-textarea"
                  >
                    <thead>
                      <tr>
                        <th />
                        <th>S/N</th>
                        <th>Item Code</th>
                        <th>Item Name</th>
                        <th>Specification</th>
                        <th>Unit Cost</th>
                        <th>Quantity</th>
                        <th>Subtotal</th>
                      </tr>
                    </thead>
                    <tbody ref={ref}>
                      {tableData &&
                        tableData.map((el, index) => (
                          <tr
                            /*  style={
                              !canPerformAction(requisition)
                                ? { pointerEvents: "none" }
                                : {}
                            } */
                            key={index}
                            className={`${activeTableIndex === index &&
                              "active-row"}`}
                            onClick={() =>
                              activeTableIndex !== index &&
                              setActiveTableIndex(index)
                            }
                          >
                            <td className="p-cursor">
                              <DotsHorizontalIcon
                                onClick={() => {
                                  setShowPurchaseOrderDistributionModal({
                                    ...el,
                                    vendor: po?.VendorsQuotes?.Vendor_ID,
                                    requestid: id,
                                  });
                                }}
                              />
                            </td>
                            <td>{index + 1}</td>
                            <td
                              className="p-cursor"
                              onClick={(e) => handleRowClick(e, index)}
                            >
                              {el.barcode}
                            </td>
                            <td>{el.itemName}</td>
                            <td>
                              <Form.Control
                                as="textarea"
                                rows={3}
                                className="form-control border-0 px-1 bg-white col-6"
                                name="specification"
                                value={el?.specification}
                                onKeyDown={(e) =>
                                  e.keyCode === 13 && e.target.blur()
                                }
                                onChange={(e) =>
                                  handleInputChange({
                                    index,
                                    name: "specification",
                                    value: e.target.value,
                                  })
                                }
                              />
                            </td>
                            <td>
                              <CurrencyInput
                                className="form-control border-1 px-1"
                                value={el.unitCost}
                                onKeyDown={(e) =>
                                  e.keyCode === 13 && e.target.blur()
                                }
                                name="unitCost"
                                onValueChange={(value, name) =>
                                  handleInputChange({
                                    index,
                                    name,
                                    value,
                                  })
                                }
                                decimalsLimit={2}
                                disabled
                              />{" "}
                            </td>
                            <td>
                              <CurrencyInput
                                className="form-control border-1 px-1"
                                value={el.quantity}
                                onKeyDown={(e) =>
                                  e.keyCode === 13 && e.target.blur()
                                }
                                name="quantity"
                                onValueChange={(value, name) =>
                                  handleInputChange({
                                    index,
                                    name,
                                    value,
                                  })
                                }
                                allowNegativeValue={false}
                                allowDecimals={true}
                                disabled
                              />
                            </td>
                            <td>
                              <CurrencyInput
                                className="form-control border-1 px-0"
                                name="subtotal"
                                disabled
                                value={
                                  !isNaN(el.unitCost * el.quantity)
                                    ? currency(el.unitCost, {
                                      precision: 2,
                                    }).multiply(el.quantity)
                                    : 0
                                }
                                onKeyDown={(e) =>
                                  e.keyCode === 13 && e.target.blur()
                                }
                                onValueChange={(value, name) =>
                                  handleInputChange({
                                    index,
                                    name,
                                    value,
                                  })
                                }
                                decimalsLimit={2}
                              />
                            </td>
                          </tr>
                        ))}
                      {/* <tr
                        style={
                          !canPerformAction(requisition)
                            ? { pointerEvents: "none" }
                            : {}
                        }
                        className={`p-cursor ${activeTableIndex === -1 &&
                          "active-row"}`}
                      >
                        <td onClick={(e) => createNew(e)}>
                          <span className="">
                            <MagnifyIcon />
                          </span>
                        </td>
                        <td />
                        <td />
                        <td />
                      </tr> */}
                    </tbody>
                  </Table>
                ) : lowerCase(requisition?.type) === "fund request" ||
                  lowerCase(requisition?.type) ===
                  "retirement for fund request" ||
                  lowerCase(requisition?.type) === "budget" ? (
                  <Table
                    responsive
                    borderless
                    striped
                    className="product-table with-large-textarea"
                  >
                    <thead>
                      <tr>
                        <th>S/N</th>
                        <th className="col-4">Description</th>
                        <th>Unit Cost</th>
                        <th>Quantity</th>
                        <th>Sub Total</th>
                      </tr>
                    </thead>
                    <tbody>
                      {tableData &&
                        tableData.map((el, index) => (
                          <tr
                            style={
                              !canPerformAction(requisition) &&
                                !canModifyFundRequest(requisition) &&
                                !(
                                  canReservedUserPerformAction ||
                                  canReservedUserSend
                                )
                                ? // authUser.Staff_ID !== requisition?.requestby
                                { pointerEvents: "none" }
                                : {}
                            }
                            key={index}
                            className={`${activeTableIndex === index &&
                              "active-row"}`}
                            onClick={() =>
                              activeTableIndex !== index &&
                              setActiveTableIndex(index)
                            }
                          >
                            <td>{index + 1}</td>
                            <td>
                              <Form.Control
                                as="textarea"
                                rows={3}
                                className="form-control border-1 px-1"
                                value={el.description}
                                onKeyDown={(e) =>
                                  e.keyCode === 13 && e.target.blur()
                                }
                                onChange={(e) =>
                                  handleInputChange({
                                    index,
                                    name: "description",
                                    value: e.target.value,
                                  })
                                }
                              />
                            </td>
                            <td>
                              <CurrencyInput
                                className="form-control border-1 px-1"
                                value={el.unitCost}
                                onKeyDown={(e) =>
                                  e.keyCode === 13 && e.target.blur()
                                }
                                name="unitCost"
                                onValueChange={(value, name) =>
                                  handleInputChange({
                                    index,
                                    name,
                                    value,
                                  })
                                }
                                decimalsLimit={2}
                              />
                            </td>
                            <td>
                              <CurrencyInput
                                className="form-control border-1 px-1"
                                value={el.quantity}
                                onKeyDown={(e) =>
                                  e.keyCode === 13 && e.target.blur()
                                }
                                name="quantity"
                                onValueChange={(value, name) =>
                                  handleInputChange({
                                    index,
                                    name,
                                    value,
                                  })
                                }
                                allowNegativeValue={false}
                                allowDecimals={true}
                              />
                            </td>
                            <td>
                              <CurrencyInput
                                className="form-control border-1 px-0"
                                name="subtotal"
                                disabled
                                value={
                                  !isNaN(el.unitCost * el.quantity)
                                    ? currency(el.unitCost, {
                                      precision: 2,
                                    }).multiply(el.quantity)
                                    : 0
                                }
                                onKeyDown={(e) =>
                                  e.keyCode === 13 && e.target.blur()
                                }
                                onValueChange={(value, name) =>
                                  handleInputChange({
                                    index,
                                    name,
                                    value,
                                  })
                                }
                                decimalsLimit={2}
                              />
                            </td>
                          </tr>
                        ))}

                      <tr
                        style={
                          !canPerformAction(requisition) &&
                            !canModifyFundRequest(requisition) &&
                            !(canReservedUserPerformAction || canReservedUserSend)
                            ? { pointerEvents: "none" }
                            : {}
                        }
                        className={`bg-light p-cursor ${activeTableIndex ===
                          -1 && "active-row"}`}
                        onClick={() =>
                          activeTableIndex !== -1 && setActiveTableIndex(-1)
                        }
                      >
                        <td />
                        <td>
                          <Form.Control
                            as="textarea"
                            rows={3}
                            className="form-control border-1 px-1 bg-white"
                            name="description"
                            id="fund-request-description"
                            value={fundRequest.description}
                            onKeyDown={(e) =>
                              e.keyCode === 13 && createNewFundRequest(e)
                            }
                            onChange={(e) =>
                              handleFundRequestInput(
                                e.target.value,
                                e.target.name
                              )
                            }
                          />
                        </td>
                        <td>
                          <CurrencyInput
                            className="form-control border-1 px-1 bg-white"
                            value={fundRequest.unitCost}
                            onKeyDown={(e) =>
                              e.keyCode === 13 && createNewFundRequest(e)
                            }
                            name="unitCost"
                            onValueChange={(value, name) =>
                              handleFundRequestInput(value, name)
                            }
                            decimalsLimit={2}
                          />
                        </td>
                        <td>
                          <CurrencyInput
                            className="form-control border-1 px-1 bg-white"
                            value={fundRequest.quantity}
                            name="quantity"
                            onKeyDown={(e) =>
                              e.keyCode === 13 && createNewFundRequest(e)
                            }
                            onValueChange={(value, name) =>
                              handleFundRequestInput(value, name)
                            }
                            allowNegativeValue={false}
                            allowDecimals={true}
                          />
                        </td>
                        <td>
                          {fundRequest.unitCost && fundRequest.quantity ? (
                            <CurrencyInput
                              className="form-control border-1 px-0 bg-white"
                              name="subtotal"
                              disabled
                              value={
                                !isNaN(
                                  fundRequest.unitCost * fundRequest.quantity
                                )
                                  ? currency(fundRequest.unitCost, {
                                    precision: 2,
                                  }).multiply(fundRequest.quantity)
                                  : ""
                              }
                              decimalsLimit={2}
                            />
                          ) : null}
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                ) : null}
                <Overlay
                  show={show}
                  onHide={() => setShow(false)}
                  target={target}
                  container={document.body}
                  containerPadding={0}
                  rootClose={true}
                  popperConfig={{
                    strategy: "fixed",
                    modifiers: [maxTopPopperConfig({})],
                  }}
                >
                  <Popover
                    style={{
                      maxWidth: "unset",
                      height: "28.75rem",
                      overflow: "hidden",
                      boxShadow: "0px 4px 20px 0px #131B2814",
                    }}
                  >
                    <ItemsTable
                      handleSelectedItem={handleSelectedItem}
                      type={
                        lowerCase(requisition?.type) !== "inventory"
                          ? requisition?.type
                          : ""
                      }
                      getItemFrom={
                        lowerCase(requisition?.type) !== "inventory"
                          ? "Equipment"
                          : "Items"
                      }
                    />
                  </Popover>
                </Overlay>
                {(canPerformAction(requisition) ||
                  canModifyFundRequest(requisition) ||
                  canReservedUserPerformAction ||
                  canReservedUserSend) &&
                  isInventoryOrFundRequest(requisition?.type) &&
                  !requisition?.status?.includes("Approved & Closed")
                  ? lowerCase(requisition?.type) !== "po" && (
                    <div className="d-flex justify-content-between pb-4 mb-4 border-bottom d-print-none">
                      {![null, -1].includes(activeTableIndex) ? (
                        <Button
                          onClick={() => removeSelection()}
                          variant="danger"
                          size="sm"
                          className="text-white px-3 "
                        >
                          Remove Selection
                        </Button>
                      ) : (
                        <span />
                      )}

                      <div className="proceed-actions gap-3">
                        {isInventoryOrFundRequest(requisition?.type) && (
                          <Button
                            className="btn-light-blue text-primary mx-4 d-inline d-md-none"
                            type="submit"
                            onClick={() => addNewFundRequest()}
                          >
                            + Add
                          </Button>
                        )}

                        <Button
                          disabled={updateRequisitionMutation.isLoading}
                          onClick={() => save()}
                          variant="outline-primary"
                          size="sm"
                        >
                          {updateRequisitionMutation.isLoading
                            ? "Please wait..."
                            : "Save"}
                        </Button>
                      </div>
                    </div>
                  )
                  : null}{" "}
              </div>

              {!isEmpty(requisition?.filledFormData) ? (
                <div>
                  {!isFetching ? (
                    <>
                      <iframe
                        ref={iframeRef}
                        id="Frame"
                        onLoad={(e) => {
                          setIsLoadingIframe(false);
                          sendSetupData();
                          setOrientation({
                            orientation,
                            pageSize,
                          });
                        }}
                        className="w-100 vh-100 border"
                        title="Form Builder"
                        src={`${process.env.REACT_APP_FORM_BUILDER_URL
                          }/form/fill`}
                      />
                      {requisition?.status === "Pending" || reciever ? (
                        <div className="mt-3">
                          <Button
                            disabled={updateRequisitionMutation.isLoading}
                            onClick={() => saveForm()}
                            variant="outline-primary"
                            size="sm"
                            className=" px-4 "
                          >
                            {updateRequisitionMutation.isLoading
                              ? "Please wait..."
                              : "Save"}
                          </Button>
                        </div>
                      ) : null}
                      <hr />
                    </>
                  ) : null}
                </div>
              ) : (
                <div className="preview-footer">
                  {isInventoryOrFundRequest(requisition?.type) &&
                    lowerCase(requisition?.type) !== "material request" ? (
                    <div>
                      <p>Total Amount: </p>
                      <InputGroup className="mb-3">
                        <InputGroup.Text id="basic-addon1">
                          {currencySymbol}
                        </InputGroup.Text>
                        <CurrencyInput
                          placeholder="0.00"
                          className="form-control"
                          //  // disableGroupSeparators
                          disabled
                          value={total}
                        />
                      </InputGroup>
                    </div>
                  ) : (
                    <hr />
                  )}

                  {/*  Allowance  */}
                  {requisition && requisition.type === "Allowance" ? (
                    <AllowanceRequest requisition={requisition} />
                  ) : null}

                  <div className="d-flex justify-content-between">
                    <div>
                      <p> Attachments: </p>
                      <FilesAttachments
                        files={attachments.map((el) =>
                          !el?.fileId
                            ? el
                            : {
                              ...el,
                              modifiedSystemFileName: `${el.systemFileName}/${el.name
                                }`,
                            }
                        )}
                        setFiles={(files) => setAttachments(files)}
                        colWidth="4"
                        url={`${requisition?.requestbyUser
                          ? backendUrl
                          : requisition?.branchUrl
                          }/api/${["rfq", "po"].includes(lowerCase(requisition?.type))
                            ? "vendors"
                            : "requisition"
                          }/files`}
                        upload={() =>
                          uploadFileMutation.mutate({
                            initialFiles: attachments,
                          })
                        }
                        deleteImage={deleteImage}
                        isLoading={uploadFileMutation?.isLoading || isLoading}
                      />
                    </div>

                    {["rfq"].includes(lowerCase(requisition?.type)) && (
                      <div>
                        <button
                          className="btn btn-primary"
                          onClick={() => {
                            IsQuote
                              ? setShowCreateQuoteModal(true)
                              : toast.error("This RFQ does not have a Quote");
                          }}
                        >
                          Select Quote
                        </button>
                      </div>
                    )}
                  </div>

                  <hr />

                  {requisition &&
                    lowerCase(requisition?.type) === "fund request" ? (
                    <div key={requisition?.timestamp}>
                      <FundRequestForm
                        requisition={requisition}
                        tableData={tableData}
                        total={total}
                        refetch={() => refetch()}
                        fundRequest={fundRequest}
                        currencySymbol={currencySymbol}
                        expensedAccounts={expensedAccounts}
                        attachedRequest={attachedRequest}
                      />
                    </div>
                  ) : null}

                  {/* Creators Signatory  */}
                  {/* if can find user, means its a cross request  */}

                  {requisition && (
                    <>
                      <Table borderless className="form-table">
                        <tbody className="d-flex">
                          <tr className="d-flex flex-column w-50">
                            <td>Name (Requester)</td>
                            <td>
                              {requisition?.requestbyUser?.Name ||
                                requisition?.sentByName ||
                                "..."}
                            </td>
                          </tr>
                          <tr className="d-flex flex-column w-50">
                            <td>Date:</td>
                            <td>
                              {format(
                                new Date(requisition.regdate),
                                "dd - MM - yyyy"
                              )}
                            </td>
                          </tr>
                        </tbody>
                      </Table>
                      <Table borderless className="form-table">
                        <tbody className="d-flex">
                          <tr className="d-flex flex-column w-50">
                            <td>{"  "}</td>
                            <td> </td>
                          </tr>
                          <tr className="d-flex flex-column w-50">
                            <td>
                              <label for="signature">Signature :</label>
                            </td>
                            <td>
                              <label className="p-cursor">
                                <div
                                  className="signature-image"
                                  style={{ height: "12rem" }}
                                >
                                  {
                                    <img
                                      style={{
                                        width: "12rem",
                                        height: "12rem",
                                      }}
                                      alt={requisition.requestby}
                                      src={
                                        requisition.requestbyUser
                                          ? `${backendUrl}/api/users/signature/${requisition.requestbyUser.Staff_ID
                                          }`
                                          : `${requisition.branchUrl
                                          }/api/users/signature/${requisition.requestby
                                          }`
                                      }
                                    />
                                  }
                                </div>
                              </label>
                            </td>
                          </tr>
                        </tbody>
                      </Table>
                    </>
                  )}

                  {requisition?.requisitiontracks.map((el, index) => (
                    <Fragment key={index}>
                      {![
                        "pending",
                        "disapproved",
                        /* "pending payment",
                        "pending retirement", */
                      ].includes(lowerCase(el.status)) ? (
                        <>
                          <Table borderless className="form-table">
                            <tbody className="d-flex">
                              <tr className="d-flex flex-column w-50">
                                <td>Name</td>
                                <td title={el?.requestby}>
                                  {el?.receivedbyUser?.Name ||
                                    el?.receivedByName ||
                                    el?.sentToDepartment}
                                </td>
                              </tr>
                              <tr className="d-flex flex-column w-50">
                                <td>Date:</td>
                                <td>
                                  {format(
                                    new Date(el.regdate),
                                    "dd - MM - yyyy"
                                  )}
                                </td>
                              </tr>
                            </tbody>
                          </Table>
                          <Table borderless className="form-table">
                            <tbody className="d-flex">
                              <tr className="d-flex flex-column w-50">
                                <td>{"  "}</td>
                                <td>{el.comment}</td>
                              </tr>
                              <tr className="d-flex flex-column w-50">
                                <td>
                                  <label for="signature">Signature :</label>
                                </td>
                                <td>
                                  <label className="p-cursor">
                                    <div
                                      className="signature-image"
                                      style={{ height: "12rem" }}
                                    >
                                      {
                                        <img
                                          style={{
                                            width: "12rem",
                                            height: "12rem",
                                          }}
                                          alt={el.receivedby}
                                          src={
                                            el?.receivedbyUser
                                              ? `${backendUrl}/api/users/signature/${el.receivedbyUser?.Staff_ID
                                              }`
                                              : `${requisition.branchUrl
                                              }/api/users/signature/${el.receivedby
                                              }`
                                          }
                                        />
                                      }
                                    </div>
                                  </label>
                                </td>
                              </tr>
                            </tbody>
                          </Table>
                        </>
                      ) : null}
                    </Fragment>
                  ))}
                </div>
              )}

              {/* */}
              <div className="d-flex justify-content-end gap-3">
                {(isAdmin || isCashier || isProcurement) && po ? (
                  <>
                    <Button
                      variant="light-blue"
                      onClick={() => navigate(`/vendor/PO?requestid=${id}`)}
                    >
                      <BookIcon />
                      View PO
                    </Button>
                  </>
                ) : null}

                {((canApproveAndClose(requisition) || isAdmin) &&
                  (requisition?.status?.includes("Approved & Closed") ||
                    requisition?.status?.includes("Pending Payment"))) ||
                  canSendTo ? (
                  <Button
                    variant="light-blue"
                    onClick={() =>
                      navigate(`/requisition/request/approve-and-close/${id}`)
                    }
                  >
                    <UserIcon />
                    Send To
                  </Button>
                ) : null}

                <Button
                  variant="light-blue"
                  onClick={() => commentOnRequisition()}
                >
                  <MessageIcon /> Comment
                </Button>

                {requisition?.status?.includes("Approved & Closed") &&
                  ["material request", "inventory"].includes(
                    lowerCase(requisition?.type)
                  ) &&
                  (isCashier || isAdmin) &&
                  requisition.hasCloseOutActions ? (
                  <>
                    {!requisition?.itemsIssued && (
                      <Button
                        variant="light-blue"
                        onClick={() => setShowGeneralItemIssuer(true)}
                      >
                        Issue Item
                      </Button>
                    )}

                    {!requisition?.poCreated && (
                      <Button variant="light-blue" onClick={() => getPoItems()}>
                        Create PO
                      </Button>
                    )}

                    {!requisition?.rfqCreated && (
                      <Button
                        variant="light-blue"
                        onClick={() => setShowNewRFQRequisitionModal(true)}
                      >
                        Create RFQ
                      </Button>
                    )}
                  </>
                ) : null}

                {/* <Button
                  variant="light-blue"
                  onClick={() => { }}
                >
                  <UndoVariantIcon /> Recall
                </Button> */}

                <Button
                  onClick={() =>
                    navigate(
                      `/requisition/request/details/${requisition.requestid}`
                    )
                  }
                >
                  View Progress
                </Button>
              </div>
            </div>
          </div>
        </Modal.Body>{" "}

        <RequisitionApproveFooter
          {...{
            requisition,
            canPerformAction,
            canReservedUserSend,
            reciever,
            approveAndClose,
            canReservedUserPerformAction,
            reject,
            preSelectedSignatoryData,
            approveOnly,
            id,
            canApproveAndClose,
            location,
          }}
        />
      </Modal>
      {loanAmount && openAccountModal ? (
        <LoanAccountModal
          onHide={() => setOpenAccountModal(false)}
          show={openAccountModal}
          refID={id}
          refetch={refetch}
          loanAmount={loanAmount}
          isLoan={true}
        />
      ) : null}

      {salaryAmount && openAccountModal ? (
        <LoanAccountModal
          onHide={() => setOpenAccountModal(false)}
          show={openAccountModal}
          refID={id}
          refetch={refetch}
          loanAmount={parseFloat(salaryAmount)}
          isSalary={true}
        />
      ) : null}
      {ShowNewPoRequisitionModal && (
        <PORequisitionModal
          setShowNewRequisitionModal={setShowNewPoRequisitionModal}
          ShowNewRequisitionModal={ShowNewPoRequisitionModal}
          mainRefetch={refetch}
          initialTableData={items}
          approvedRequisitionId={requisition.requestid}
          incomingAttachments={attachments}
        />
      )}

      {ShowNewRFQRequisitionModal && (
        <InItemsRequisitionModal
          setShowNewRequisitionModal={setShowNewRFQRequisitionModal}
          ShowNewRequisitionModal={ShowNewRFQRequisitionModal}
          mainRefetch={refetch}
          selectedRFQ={items}
          approvedRequisitionId={requisition.requestid}
          incomingAttachments={attachments}
        />
      )}

      {showGeneralItemIssuer && items ? (
        <GeneralItemIssuerModal
          showGeneralItemIssuer={showGeneralItemIssuer}
          setShowGeneralItemIssuer={setShowGeneralItemIssuer}
          initialTableData={items}
          requisition={requisition}
          refetch={refetch}
        />
      ) : null}

      {showPurchaseOrderDistributionModal && (
        <PurchaseOrderDistributionModal
          purchaseOrder={showPurchaseOrderDistributionModal}
          showPurchaseOrderDistributionModal={
            showPurchaseOrderDistributionModal
          }
          setShowPurchaseOrderDistributionModal={
            setShowPurchaseOrderDistributionModal
          }
          POIdUnavailable
        />
      )}

      {showCreateQuoteModal && (
        <RFQCompanyModal
          show={showCreateQuoteModal}
          onHide={setShowCreateQuoteModal}
          selectedRFQ={RFQData}
          disableInput={true}
          headerNo={`RFQ No : ${RFQData?.RFQId}`}
          headerName={`RFQ Name : ${RFQData?.formName}`}
          theRFQName={RFQData?.formName}
        />
      )}

      <ModalLoader show={isLoadingIframe} />
    </div>
  );
}
